import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { NgwWowService } from './../shared/wow/wow.service';
import { PageMetaService } from './../shared/page-meta.service';
import { YtplaylistService } from '../shared/ytplaylist.service';
import { NgbdYtwin } from './../services/ytwin.component';

@Component({
  selector: 'app-recent',
  templateUrl: './recent.component.html',
  styleUrls: ['./recent.component.scss']
})
export class RecentComponent implements OnInit, AfterViewInit {
  recentItems: any[] = [];

  constructor(
    private route: ActivatedRoute,
    private ytpSvrc: YtplaylistService,
    private modalService: NgbModal,
    private pageMeta: PageMetaService,
    private wowService: NgwWowService
  ) {}

  ngOnInit() {
    this.recentItems = this.route.snapshot.data['recentItems'];
    this.pageMeta.addMeta({ title: 'Our Recent Work - Differentangles' });
  }

  ngAfterViewInit() {
    this.wowService.init();
  }

  getColClass(index) {
    var inde = (index + 1) % 6;
    if (inde === 1 || inde === 2) {
      return 'col-md-6';
    } else {
      return 'col-md-3';
    }
  }

  showVideo(e) {
    e.preventDefault();
    let videoId = e.currentTarget.attributes['data-yt-video'].value;
    const modalRef = this.modalService.open(NgbdYtwin, {
      centered: true,
      size: 'lg',
      windowClass: 'dark-modal'
    });
    modalRef.componentInstance.ytUrl = this.ytpSvrc.getYtUrl(videoId);
  }
}
