import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { Observable, Subject } from 'rxjs';

import { YtplaylistService } from './ytplaylist.service';

@Injectable({
  providedIn: 'root'
})
export class ResolversService {
  constructor() {}
}

@Injectable({
  providedIn: 'root'
})
export class ClientListResolver implements Resolve<ClientList[]> {
  constructor(private http: HttpClient) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<ClientList[]> {
    return this.http.get<ClientList[]>('/data/clients.json');
  }
}

@Injectable({
  providedIn: 'root'
})
export class AdvResolver implements Resolve<ImageGallery[]> {
  constructor(private http: HttpClient) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<ImageGallery[]> {
    return this.http.get<ImageGallery[]>('/data/datadcol.json');
  }
}

@Injectable({
  providedIn: 'root'
})
export class EvntResolver implements Resolve<ImageGallery[]> {
  constructor(private http: HttpClient) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<ImageGallery[]> {
    return this.http.get<ImageGallery[]>('/data/dataevcol.json');
  }
}

@Injectable({
  providedIn: 'root'
})
export class DubbingResolver implements Resolve<DAGenericObject> {
  constructor(private ytpSvrc: YtplaylistService, private http: HttpClient) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<DAGenericObject> {
    let objRes: Subject<DAGenericObject> = new Subject<DAGenericObject>();
    let respObj: DAGenericObject = {
      ytv: null,
      gal: null
    };
    let respCount = 0;
    let triggerVal = () => {
      if (respCount >= 2) {
        objRes.next(respObj);
        objRes.complete();
      }
    };
    this.ytpSvrc.getYtPlaylist('PL1jJnUH17CKW7GWpehr8ZHQLY6ddYBsBw').subscribe(
      data => {
        respObj['ytv'] = data;
        respCount += 1;
        triggerVal();
      },
      error => {
        console.log(error);
      }
    );
    this.http.get<ImageGallery[]>('/data/arabization.json').subscribe(
      data => {
        respObj['gal'] = data;
        respCount += 1;
        triggerVal();
      },
      error => {
        console.log(error);
      }
    );
    return objRes;
  }
}

@Injectable({
  providedIn: 'root'
})
export class FilmResolver implements Resolve<DAGenericObject> {
  constructor(private ytpSvrc: YtplaylistService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<DAGenericObject> {
    let objRes: Subject<DAGenericObject> = new Subject<DAGenericObject>();
    let respObj: DAGenericObject = {
      tvc: null,
      cvd: null,
      ani: null
    };
    let respCount: number = 0;
    let triggerVal = () => {
      if (respCount >= 3) {
        objRes.next(respObj);
        objRes.complete();
      }
    };
    this.ytpSvrc.getYtPlaylist('PL1jJnUH17CKX8Zf4MOjRlY7rmqy3TP-_v').subscribe(
      data => {
        respObj['tvc'] = data;
        respCount += 1;
        triggerVal();
      },
      error => {
        console.log(error);
      }
    );
    this.ytpSvrc.getYtPlaylist('PL1jJnUH17CKV0onhykFXKDQ0vz-6j0wy4').subscribe(
      data => {
        respObj['cvd'] = data;
        respCount += 1;
        triggerVal();
      },
      error => {
        console.log(error);
      }
    );
    this.ytpSvrc.getYtPlaylist('PL1jJnUH17CKXxBYe6hH1oYKbtCDX5TyWE').subscribe(
      data => {
        respObj['ani'] = data;
        respCount += 1;
        triggerVal();
      },
      error => {
        console.log(error);
      }
    );
    return objRes;
  }
}

@Injectable({
  providedIn: 'root'
})
export class VrResolver implements Resolve<any[]> {
  constructor(private ytpSvrc: YtplaylistService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any[]> {
    return this.ytpSvrc.getYtPlaylist('PL1jJnUH17CKV5dkELEDCdsttoFFm2UikK');
  }
}

@Injectable({
  providedIn: 'root'
})
export class ArResolver implements Resolve<any[]> {
  constructor(private ytpSvrc: YtplaylistService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any[]> {
    return this.ytpSvrc.getYtPlaylist('PL1jJnUH17CKUSL6kI58hTKygEKrYx22xT');
  }
}

@Injectable({
  providedIn: 'root'
})
export class RecentResolver implements Resolve<any[]> {
  constructor(private ytpSvrc: YtplaylistService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any[]> {
    return this.ytpSvrc.getYtPlaylist('PL1jJnUH17CKW4sBIdls5EMeGpA13GwNsh');
  }
}

export interface DAGenericObject {
  [key: string]: any;
}

export interface ClientList {
  url: string;
  title: string;
}

export interface ImageGallery {
  url: string;
  thumb: string;
}
