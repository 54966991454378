import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InitLoadService {

  private isInitLoad:boolean;

  constructor() {
    this.isInitLoad = true;
   }

   get isinit (){
     return this.isInitLoad
   }

   set isinit(val:boolean){
     this.isInitLoad = val;
   }
}
