<div class="row">
  <div id="home-intro" class="columns col-12 padding5">
    <div class="woodrow">
      <div id="maintext" class="desktopimg padding20">
        <p>
          <span class="highlight fadeInUp">We conceive, produce and direct TV commercials, short films,
            documentaries, corporate videos and the like. A whole host of other
            genres make up this repertoire.</span>
        </p>
        <p>
          <span class="highlight fadeInUp">The <a routerLink="/services/augmented-reality">AR</a>, VR and
            Gaming bug has now infected our bloodstream, and ‘we are’ (no pun
            intended) excited to offer this as part of our services.</span>
        </p>
        <p>
          <span class="highlight fadeInUp">Ideating and staging ‘Events’ gives us a ‘high’ like no other. As
            professionals we guarantee you a 360 degree ROI. :)</span>
        </p>
        <p>
          <span class="highlight fadeInUp"><a href="mailto:info@differentangles.net">Contact us</a> for ‘ <strong>your</strong> slice of our life’. Let’s
            consider all angles... Different Angles, UAE. Since 2010</span>
        </p>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="columns col-sm-6 col-12 padding0">
    <div class="row">
      <div class="columns col-6 padding5 flip-container">
        <div class="flipper">
          <div class="box front">
            <a routerLink="/services/film-production" class="boxlink">
              <span class="linkcontent">
                <i>Film Production<br />
                  &amp; Animation</i>
                <p>
                  &ldquo; I saw an angel in the marble <br />and carved until I
                  set it free &rdquo;
                </p>
              </span>
            </a>
          </div>
          <div class="box2 back doodles doodle03">
            <a routerLink="/services/film-production" class="boxlink">
              <span class="linkcontent">
                <i>Film Production <br />
                  &amp; Animation</i>
                <p>&nbsp; <br />&nbsp;</p>
              </span>
            </a>
          </div>
        </div>
      </div>
      <div class="columns col-6 padding5">
        <div class="growbox">
          <img src="/assets/images/home/angel.jpg" alt="Angel" class="autoheight grow" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="columns col-6 padding5">
        <div class="growbox">
          <img src="/assets/images/home/vr.jpg" alt="Virtual Reality" class="autoheight grow" />
        </div>
      </div>
      <div class="columns col-6 padding5 flip-container">
        <div class="flipper">
          <div class="box front">
            <a routerLink="/services/augmented-reality" class="boxlink">
              <span class="linkcontent">
                <i>Augmented Reality <br />
                  &amp; Gaming</i>
                <p>
                  &ldquo; It's not what you look at <br />that matters, but what
                  you see &rdquo;
                </p>
              </span>
            </a>
          </div>
          <div class="box2 back doodles doodle02">
            <a routerLink="/services/augmented-reality" class="boxlink">
              <span class="linkcontent">
                <i>Augmented Reality <br />
                  &amp; Gaming</i>
                <p>&nbsp; <br />&nbsp;</p>
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="columns col-sm-6 col-12 padding5">
    <div id="recentbox" class="growbox">
      <a class="recent-works" routerLink="/recent">
        Recent Work
      </a>
      <img src="/assets/images/home/highland.jpg" alt="Hightland" class="autoheight grow" />
    </div>
  </div>
</div>
<div class="row">
  <div class="columns col-sm-6 col-12 padding5">
    <div class="growbox">
      <img src="/assets/images/home/workdesk.jpg" alt="Workdesk" class="autoheight grow" />
    </div>
  </div>
  <div class="columns col-sm-6 col-12 padding0">
    <div class="row">
      <div class="columns col-6 padding5">
        <div class="growbox">
          <img src="/assets/images/home/grafitti.jpg" alt="Advertising" class="autoheight grow" />
        </div>
      </div>
      <div class="columns col-6 padding5 flip-container">
        <div class="flipper">
          <div class="box front">
            <a routerLink="/services/advertising" class="boxlink">
              <span class="linkcontent">
                <i>Advertising <br />Solutions</i>
                <p>
                  &ldquo; Life does not imitate art. <br />Life Is art &rdquo;
                </p>
              </span>
            </a>
          </div>
          <div class="box2 back doodles doodle04">
            <a routerLink="/services/advertising" class="boxlink">
              <span class="linkcontent">
                <i>Advertising <br />Solutions</i>
                <p>&nbsp; <br />&nbsp;</p>
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="columns col-6 padding5 flip-container">
        <div class="flipper">
          <div class="box front">
            <a routerLink="/services/events" class="boxlink">
              <span class="linkcontent">
                <i>Events</i>
                <p>
                  &ldquo; Criticize our work <br />and you become the teacher
                  &rdquo;
                </p>
              </span>
            </a>
          </div>
          <div class="box2 back doodles doodle06">
            <a routerLink="/services/events" class="boxlink">
              <span class="linkcontent">
                <i>Events</i>
                <p>&nbsp; <br />&nbsp;</p>
              </span>
            </a>
          </div>
        </div>
      </div>
      <div class="columns col-6 padding5">
        <div class="growbox">
          <img src="/assets/images/home/photographer.jpg" alt="Events" class="autoheight grow" />
        </div>
      </div>
    </div>
  </div>
</div>
