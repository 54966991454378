import { Component, OnInit } from '@angular/core';

import * as $ from 'jquery';

import { PageMetaService } from './../shared/page-meta.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  constructor(private pageMeta: PageMetaService) {}

  ngOnInit() {
    let jq = $;
    this.pageMeta.addMeta({ title: 'Welcome to Differentangles' });
  }
}
