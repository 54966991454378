<div id="viewport">
  <div id="left-menu" class="d-none d-md-block">
    <a routerLink="/">
      <img src="/assets/images/logo/logo.png" alt="DifferentAngles" id="homelogo" />
    </a>
    <div id="menuPanel" ng-scrollbars>
      <ul auto-active>
        <li>
          <a routerLinkActive="current" [routerLinkActiveOptions]="{ exact: true }" routerLink="/">HOME</a>
        </li>
        <li>
          <a routerLinkActive="current" [routerLinkActiveOptions]="{ exact: true }" routerLink="/about">ABOUT US</a>
          <ul>
            <li>
              <a routerLinkActive="current" routerLink="/about/clients">CLIENTS</a>
            </li>
          </ul>
        </li>
        <li>
          <a routerLinkActive="current" [routerLinkActiveOptions]="{ exact: true }" routerLink="/services">WHAT WE DO</a>
          <ul>
            <li>
              <a routerLinkActive="current" routerLink="/services/film-production">FILM PRODUCTION &amp; ANIMATION</a>
            </li>
            <li>
              <a routerLinkActive="current" routerLink="/services/augmented-reality">AUGMENTED REALITY &amp; GAMING</a>
            </li>
            <li>
              <a routerLinkActive="current" routerLink="/services/virtual-reality">VIRTUAL REALITY &amp; 360&deg; VIDEOS</a>
            </li>
            <li>
              <a routerLinkActive="current" routerLink="/services/advertising">ADVERTISING SOLUTIONS</a>
            </li>
            <li>
              <a routerLinkActive="current" routerLink="/services/events">EVENTS</a>
            </li>
            <li>
              <a routerLinkActive="current" routerLink="/services/arabization">DUBBING AND ARABIZATIONS</a>
            </li>
            <li>
              <a routerLinkActive="current" routerLink="/services/web">WEB DEVELOPMENT</a>
            </li>
          </ul>
        </li>
        <li>
          <a routerLinkActive="current" [routerLinkActiveOptions]="{ exact: true }" routerLink="/products">VR / AR PRODUCTS</a>
        </li>
      </ul>
    </div>
    <div id="socialBtns" class="btn-group" role="group">
      <a href="https://www.facebook.com/differentanglesfze/" target="_blank" class="btn btn-default" title="Coming Soon">
        <fa-icon [icon]="faFB"></fa-icon>
      </a>
      <a href="https://www.instagram.com/differentanglesfze/" target="_blank" class="btn btn-default" title="Instagram">
        <fa-icon [icon]="faIN"></fa-icon>
      </a>
      <a href="https://www.youtube.com/channel/UC2QdVKyXuqYY3o6MF-NVlFQ" target="_blank" class="btn btn-default" title="YouTube">
        <fa-icon [icon]="faYT"></fa-icon>
      </a>
    </div>
  </div>
  <div id="page-area" class="container_fluid">
    <div class="row d-block d-md-none">
      <nav class="navbar fixed-top navbar-light mobile-menu">
        <a class="navbar-brand" routerLink="/" style="padding-top: 5px !important;">
          <img src="/assets/images/logo/logo-long.png" alt="Different Angles" style="width:80% !important; height: auto !important;" />
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarMenu">
          <fa-icon [icon]="faMenu"></fa-icon>
        </button>
        <div id="navbarMenu" class="collapse navbar-collapse" data-bs-toggle="collapse" data-bs-target="#navbarMenu">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item">
              <a class="nav-link" routerLink="/">HOME</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/about">ABOUT US</a>
              <ul class="navbar-nav level2">
                <li class="nav-item">
                  <a class="nav-link" routerLink="/about/clients">CLIENTS</a>
                </li>
              </ul>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/services">WHAT WE DO</a>
              <ul class="navbar-nav level2">
                <li class="nav-item">
                  <a class="nav-link" routerLink="/services/film-production">FILM PRODUCTION &amp; ANIMATION</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/services/augmented-reality">AUGMENTED REALITY &amp; GAMING</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/services/virtual-reality">VIRTUAL REALITY &amp; 360&deg; VIDEOS</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/services/advertising">ADVERTISING SOLUTIONS</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/services/events">EVENTS</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/services/arabization">DUBBING AND ARABIZATIONS</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/services/web">WEB DEVELOPMENT</a>
                </li>
              </ul>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/products">VR / AR PRODUCTS</a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
    <div class="row contentarea">
      <router-outlet></router-outlet>
    </div>
    <div class="row footer">
      <div class="columns col-md-8 padding20 paddingl20vw">
        <div class="row">
          <div class="columns col-6">
            <p class="footertxt">
              CONTACT US:
              <br />
              <br />
              Different Angles FZE, <br />
              17, The Iridium, <br />
              Umm Suquiem Road, Al Barsha <br />
              PO Box. 46125, Dubai, UAE. <br />
              Phone: +971 (04) 3957893 <br />
              email:
              <a href="mailto:info@differentangles.net">info@differentangles.net</a>
            </p>
          </div>
          <div class="columns col-6">
            <p class="footertxt">
              &nbsp;
              <br />
              &nbsp; <br />
              Different Angles Pvt. Ltd. <br />
              303, Gold Coin <br />
              Near St. Anthony's School <br />
              Santacruz, Mumbai 400055 <br />
              Ph: +91 98192 64092
            </p>
          </div>
        </div>
      </div>
      <div class="columns col-md-4 d-none d-md-block relative">
        <a href="https://goo.gl/maps/ujRju2Rc1c72" target="_blank" class="viewmap">View Map</a>
      </div>
    </div>
  </div>
</div>

<div id="logoani">
  <img src="/assets/images/logo/logo0.png" id="logo0" class="logoElements" />
  <img src="/assets/images/logo/logo1.png" id="logo1" class="logoElements" />
  <img src="/assets/images/logo/logo2.png" id="logo2" class="logoElements" />
  <img src="/assets/images/logo/logo.png" id="logomain" class="logoElements" />
  <div id="slogan" class="slogan">
    Advertising, Events, Films, Web, Virtual Reality <br />
    Augmented Reality, Game Development
  </div>
  <div id="Ebutton">
    <a href="#" id="enterSite">ENTER SITE</a>
  </div>
</div>
<div id="loader">
  <div id="diagonal-loader">
    <div id="loadrevealtop"></div>
    <div id="loadrevealbottom">
      <div id="loadProgress"></div>
    </div>
  </div>
</div>
<div *ngIf="isNavigating" id="spinloader">
  <div class="spinner">
    <div class="cube1"></div>
    <div class="cube2"></div>
  </div>
</div>