import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { RecentResolver } from './shared/resolvers.service';
import { HomeComponent } from './common/home.component';
import { RecentComponent } from './common/recent.component';
import { NotfoundComponent } from './common/notfound.component';

const routes: Routes = [
  { path: 'about', loadChildren: () => import('./about/about.module').then(m => m.AboutModule) },
  {
    path: 'products',
    loadChildren: () =>
      import('./products/products.module').then(mod => mod.ProductsModule)
  },
  {
    path: 'services',
    loadChildren: () =>
      import('./services/services.module').then(mod => mod.ServicesModule)
  },
  {
    path: 'recent',
    component: RecentComponent,
    resolve: { recentItems: RecentResolver }
  },
  { path: '', component: HomeComponent },
  { path: '**', component: NotfoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
