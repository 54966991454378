import {Component, Input} from '@angular/core';

import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ngbd-yt',
  template: `
  <button class="modalclose" aria-label="close" (click)="activeModal.dismiss('Close');"><span aria-hidden="true">&times;</span></button>
  <div class="modal-body" >
    <div class="modal-content">
      <div class="videowrapper">
        <iframe [src]="ytUrl | safeurl" allowfullscreen></iframe>
      </div>
    </div>
  </div>
  `
})
export class NgbdYtwin {
  @Input() ytUrl;
  constructor(public activeModal: NgbActiveModal) {}
}