import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class YtplaylistService {

  constructor(private _http: HttpClient) { }

  public getYtPlaylist(playlistID: string): Observable<any[]> {
    let ytPlItems = [];
    let ytObs:Subject<any[]> = new Subject<any[]>();
    let ytPlUrl = 'https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=50&playlistId='+playlistID+'&key=AIzaSyBhyWXFkMlSA9G9lqCyF6egWKhH6K9u-Ys';
    let fetchPlayList = (feUrl) => {
      this._http.get<any>(feUrl)
      .subscribe(
        data => {
          data.items.forEach(item => {
            ytPlItems.push(item.snippet);
          });
          if(data.nextPageToken){
            let nyttUrl = ytPlUrl+'&pageToken='+(String(data.nextPageToken));
            fetchPlayList(nyttUrl);
          } else {
            ytObs.next(ytPlItems);
            ytObs.complete();
          }
        },
        error => {
          console.log(error);
        }
      )

    };
    fetchPlayList(ytPlUrl);
    return ytObs;
  }

  public getYtUrl(videoID: string): string {
    return 'https://www.youtube.com/embed/' + videoID + '?rel=0&autoplay=1';
  }
}
