<div class="row">
  <div
    class="heroimage heroweb columns col-12 padding5 wow bounceInRight"
  ></div>
</div>
<div class="row">
  <div class="columns col-12 padding20">
    <h2 class="centertxt wow fadeInUp" data-wow-offset="30">RECENT WORK</h2>
    <div class="underline wow fadeIn" data-wow-delay="0.2s"></div>
    <p></p>
  </div>
</div>
<div class="row row-centered">
  <div
    class="columns wow fadeInUp padding20"
    data-wow-offset="30"
    *ngFor="let recent of recentItems; let i = index"
    [ngClass]="getColClass(i)"
  >
    <a
      href="#"
      [attr.data-yt-video]="recent.resourceId.videoId"
      (click)="showVideo($event)"
      class="videoBtn"
    >
      <div class="sixteen-nine">
        <div
          class="content"
          [ngStyle]="{
            'background-image': 'url(' + recent.thumbnails.standard.url + ')'
          }"
        >
          <div class="titleText">{{ recent.title }}</div>
        </div>
      </div>
    </a>
  </div>
</div>
