import { Component, OnInit } from '@angular/core';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

import { PageMetaService } from './../shared/page-meta.service';

@Component({
  selector: 'app-notfound',
  templateUrl: './notfound.component.html',
  styleUrls: ['./notfound.component.scss']
})
export class NotfoundComponent implements OnInit {

  faWarning = faExclamationTriangle;
  
  constructor(private pageMeta:PageMetaService) { }

  ngOnInit() {
    this.pageMeta.addMeta({title:'The page not found - Differentangles'});
  }

}
