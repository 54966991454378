import { Component, OnInit, AfterViewInit, OnDestroy } from "@angular/core";
import { Title } from "@angular/platform-browser";
import {
  Router,
  NavigationStart,
  NavigationCancel,
  NavigationError,
  NavigationEnd
} from "@angular/router";

import { Subscription } from "rxjs";

import { gsap } from "gsap";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import {
  faYoutube,
  faInstagram,
  faFacebookF
} from "@fortawesome/free-brands-svg-icons";

import { InitLoadService } from "./shared/init-load.service";
import { PageMetaService } from "./shared/page-meta.service";

declare global {
  interface JQueryStatic {
    html5Loader: any;
  }
  interface JQuery {
    centerObject(): JQuery;
  }
}

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  faFB = faFacebookF;
  faIN = faInstagram;
  faYT = faYoutube;
  faMenu = faBars;
  isNavigating: boolean;
  pageMetaSub: Subscription;

  constructor(
    private router: Router,
    private initSvrc: InitLoadService,
    private pageTitle: Title,
    private pageMeta: PageMetaService
  ) {
    this.isNavigating = false;
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        window.scrollTo(0, 0);
        if (!this.initSvrc.isinit) this.isNavigating = true;
      }
      if (
        event instanceof NavigationCancel ||
        event instanceof NavigationError ||
        event instanceof NavigationEnd
      ) {
        this.isNavigating = false;
      }
    });
  }

  ngOnInit() {
    this.pageMetaSub = this.pageMeta.metaData.subscribe(result => {
      if (result.title) {
        this.pageTitle.setTitle(result.title);
      }
    });
    let centerX, centerY, isHome;
    $.fn.extend({
      centerObject: function() {
        this.css("top", centerY - this.height() / 2);
        this.css("left", centerX - this.width() / 2);
        return this;
      }
    });

    let updateCenter = function() {
      centerX = Math.ceil($(window).width() / 2);
      centerY = Math.ceil($(window).height() / 2);
    };

    let centerIntroLogo = function() {
      $("#logo0").centerObject();
      $("#logo1").centerObject();
      $("#logo2").centerObject();
      $("#logomain").centerObject();
      $("#slogan").centerObject();
      $("#Ebutton").centerObject();
    };

    let hideLogo = function() {
      $("#logoani").hide();
      $(window).unbind("resize", centerIntroLogo);
    };

    let hideLoader = function() {
      $("#loader").hide();
    };

    let loadBarHide = function() {
      $("#loadProgress").hide();
    };
    let loadBarShow = function() {
      $("#loadProgress").show();
    };

    let openerAni = function() {
      $("#logo0").hide();
      $("#logo1").hide();
      $("#logo2").hide();
      $("#logomain").show();
    };

    let finishAni = function() {
      let tl2 = gsap.timeline({ onComplete: hideLogo });
      tl2
        .addLabel("init", 0)
        .addLabel("open", 1)
        .to("#slogan", 0.5, { autoAlpha: 0 }, "init")
        .to("#enterSite", 0.5, { autoAlpha: 0 }, "init")
        .to("#logomain", 1, { autoAlpha: 0 }, "init")
        .to("#logoani", 1, { backgroundColor: "#000000" }, "init")
        .call(loadBarShow, null, "init")
        .set("#viewport", { display: "block" }, "open")
        .set("#diagonal-loader", { rotation: 45 }, "open")
        .set("#loadrevealtop", { top: 0 }, "open")
        .set("#loadrevealbottom", { bottom: 0 }, "open")
        .set("#loader", { display: "block" }, "open")
        .set("#logoani", { backgroundColor: "transparent" }, "open")
        .set("#loadProgress", { width: "0.01%" }, "open")
        .call(revealPage, null, "open");
    };

    let startLogoAnim = function(isHome: boolean = false) {
      let tl1 = gsap.timeline({ onComplete: openerAni });
      $("#logoani").show();
      //$('#openerLeft').show();
      //$('#openerRight').show();
      if (!isHome) {
        $("#enterSite").hide();
      }
      $(window).bind("resize", centerIntroLogo);
      tl1
        .addLabel("init", 0)
        .fromTo(
          "#logo2",
          0.5,
          { top: -400 },
          { top: centerY - $("#logo2").height() / 2 },
          "init"
        )
        .fromTo(
          "#logo1",
          0.5,
          { left: -200 },
          { left: centerX - $("#logo1").width() / 2 },
          "init"
        )
        .from("#logo0", 1, { autoAlpha: 0 })
        .from("#slogan", 1, { autoAlpha: 0 })
        .set("#loader", { display: "none" });
      if (isHome) {
        tl1.from("#enterSite", 1, { autoAlpha: 0 });
      } else {
        gsap.delayedCall(5, finishAni);
      }
    };

    let revealPage = function() {
      let tl3 = gsap.timeline({ onComplete: hideLoader });
      tl3
        .addLabel("init", 0)
        .addLabel("open", 1.5)
        .add(
          gsap.to("#loadProgress", 4, {
            width: "100%",
            onComplete: loadBarHide
          }),
          "init"
        )
        .to("#loadrevealtop", 3, { top: "-50%" }, "open")
        .to("#loadrevealbottom", 3, { bottom: "-50%" }, "open")
        .call(loadBarHide, null, "open");
    };

    let revealSite = function() {
      hideLogo();
      isHome = window.location.pathname == "/" ? true : false;
      let tl0 = gsap.timeline();
      tl0
        .addLabel("init", 0)
        .addLabel("ani", 1)
        .call(loadBarHide, null, "init")
        .to("#loadrevealtop", 3, { top: "-50%" }, "init")
        .to("#loadrevealbottom", 3, { bottom: "-50%" }, "init")
        .set("#loader", { backgroundColor: "transparent" }, "init")
        .call(startLogoAnim, [isHome], "ani");
    };

    $(window).resize(function() {
      updateCenter();
    });

    // HTML5 Loader have some issue so need to find another solution
    $.html5Loader({
      filesToLoad: "/data/preload.json",
      onComplete: function() {
        gsap.to("#loadProgress", 1, {
          width: "100%",
          onComplete: revealSite
        });
      },
      onUpdate: function(percentage) {
        let percent = percentage + "%";
        gsap.to("#loadProgress", 1, { width: percent });
      }
    });

    $(function() {
      updateCenter();
      centerIntroLogo();
      $("#enterSite").click(function(e) {
        e.preventDefault();
        finishAni();
      });
      // revealSite();
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.initSvrc.isinit = false;
    }, 10000);
  }

  ngOnDestroy() {
    this.pageMetaSub.unsubscribe();
    this.pageMeta.endMeta();
  }
}
