import { Injectable } from '@angular/core';
import { Observable, Observer, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PageMetaService {
  public metaData:Observable<any>;

  private metaSub = new Subject();
  constructor() { 
    this.metaData = Observable.create((observer:Observer<any>) => {
      this.metaSub.subscribe( result => {
        observer.next(result);
      })
    });
  }

  addMeta(meta:any){
    this.metaSub.next(meta);
  }

  endMeta(){
    this.metaSub.complete();
  }
}
