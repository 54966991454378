import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { SafeurlPipe } from './safeurl.pipe';

@NgModule({
  imports: [
    CommonModule,
    FontAwesomeModule
  ],
  declarations: [
    SafeurlPipe
  ],
  exports:[
    SafeurlPipe,
    FontAwesomeModule,
    CommonModule
  ]
})
export class SharedModule { }
